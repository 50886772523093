import get from "lodash/get";

class ErrorResponseClass {
  constructor(errors = null) {
    this.errors = errors;
  }

  set(errors) {
    this.errors = errors;
  }

  reset() {
    this.errors = null;
  }

  general() {
    const errors = get(this.errors, "general", null);
    return errors ? errors : [];
  }

  fields() {
    const errors = get(this.errors, "fields", null);
    return errors ? errors : {};
  }

  hasErrors() {
    return this.hasGeneralErrors() || this.hasFieldsErrors();
  }

  hasGeneralErrors() {
    const errors = get(this.errors, "general", null);
    return errors && errors.length > 0;
  }

  hasFieldsErrors() {
    const errors = get(this.errors, "fields", null);
    return errors && Object.keys(errors).length > 0;
  }

  isValidField(field) {
    return get(this.errors, `fields.${field}`, undefined) !== undefined
      ? false
      : undefined;
  }

  hasFieldsError(field) {
    const val = get(this.errors, `fields.${field}`, null);
    return val !== null;
  }

  fieldError(field) {
    const val = get(this.errors, `fields.${field}`, undefined);
    if (val !== undefined) {
      return Array.isArray(val) ? val.join(" ") : val;
    }
    return "";
  }
}

const errorResponse = (errors = null) => {
  return new ErrorResponseClass(errors);
};

export default errorResponse;
